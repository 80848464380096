import styled, { css } from 'styled-components'
// import { colors } from './constants'

const absoluteWrapper = css`
  position: absolute;
  text-align: center !important;
`
const ticket = css`
  font-weight: bold;
  margin: 0;
`

export const AlertTicketWrapper = styled.div`
  ${absoluteWrapper};
  width: 100vw;
  top: 800px;
`
export const AlertTicket = styled.p`
  ${ticket};
  font-size: 300px;
  line-height: 1;
`

export const TicketWrapper = styled.div`
  ${absoluteWrapper};
`
export const Ticket = styled.p`
  ${ticket};
`

export const ConfigErrorWrapper = styled.div`
  ${absoluteWrapper};
  top: 60%;
  width: 100%;
  font-size: 50px;
`