import React, { Component } from 'react'

import api from 'Common/api'
import { getQueryParam, handleServerError } from 'Common/utils'

import {
  AlertTicketWrapper,
  AlertTicket,
  TicketWrapper,
  Ticket,
  ConfigErrorWrapper,
} from 'Common/styled'

class App extends Component {
  state = {
    isLoading: false,
    hasConfigError: false,
    alert: false,
    alertTickets: [],
    queues: [],
  }
  componentDidMount () {
    const displayId = getQueryParam('displayId')
    const ip = getQueryParam('ip')
    if (displayId) {
      return this.getConfig(displayId)
    } else if (ip) {
      return this.getDisplayId(ip)
    }
    this.getInternalIp()
  }

  getInternalIp = async () => {
    try {
      const res = await api.internalIp.get()
      const ip = res.data.ip.private[0]

      this.setState({ ip })

      if (res.status === 200) {
        return this.getDisplayId(ip)
      }

      this.setState({ isLoading: false, hasConfigError: true })
    } catch (error) {
      this.setState({ isLoading: false, hasConfigError: true })
    }
  }

  getDisplayId = async ip => {
    try {
      const res = await api.display.getId(ip)
      const { configId: displayId, deviceId } = res.data

      this.setState({ ip, deviceId, displayId })

      if (res.status === 200) {
        return this.getConfig(displayId)
      }
      this.setState({ isLoading: false, hasConfigError: true })

    } catch (error) {
      this.setState({ isLoading: false, hasConfigError: true })
    }
  }

  getConfig = async (displayId) => {
    const { queues } = this.state
    this.setState({ isLoading: true, alert: false })
    try {
      const res = await api.display.getConfig(displayId)
      const { status, data } = res
      let newState = { isLoading: false, hasConfigError: status !== 200 }

      const oldTickets = queues.map(q => q.ticketNumber)
      const newTickets = data.config?.queues.map(q => q.ticketNumber) || []
      const diff = newTickets.filter(
        ticket => oldTickets.length > 0 && !oldTickets.includes(ticket)
      )

      if (diff.length > 0) {
        setTimeout(() => {
          this.setState({ alert: true, alertTickets: diff })
          document.getElementById('till-sound').load()
          document.getElementById('till-sound').play()
        }, 1000)
      }

      if (status === 200) {
        newState = { ...newState, ...data.config }
        this.startTimer(data.config.refreshTimer)
      }
      return this.setState(newState)
    } catch (error) {
      console.log(error)
      return handleServerError(error)
    }
  }

  startTimer (timer) {
    const displayId = this.state.displayId || getQueryParam('displayId')
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => this.getConfig(displayId), timer * 1000)
  }

  renderAlertTemplate () {
    const { alertTemplateName, alertTickets } = this.state
    return (
      <>
        <AlertTicketWrapper>
          {alertTickets.slice(0, 3).map(t => (
            <AlertTicket key={t}>{t}</AlertTicket>
          ))}
        </AlertTicketWrapper>
        <img
          src={`${process.env.REACT_APP_API_URL}/displays/getTemplate?templateName=${alertTemplateName}`}
          alt="display"
        />
      </>
    )
  }
  getTemplateStyle () {
    const { templateId } = this.state
    let style = {
      wrapper: {
        left: '300px',
        width: '780px',
        height: '1920px'
      },
    }
    switch (templateId) {
      case 'P3.1':
        style.ticket = {
          fontSize: 150,
          height: '350px',
          marginTop: '250px',
        }
        break
      case 'P4.1':
        style.ticket = {
          fontSize: 150,
          height: '250px',
          marginTop: '200px'
        }
        break
      case 'P6.1':
        style.ticket = {
          fontSize: 150,
          height: '270px',
          marginTop: '30px',
        }
        break
      case 'P1.1':
        style.wrapper = {
          left: 0,
          right: 0,
          top: '1200px',
          height: '610px',
        }
        style.ticket = {
          fontSize: 250,
          height: '400px',
          marginTop: '210px',
        }
        break
      case 'P2.1':
        style.wrapper = {
          left: 0,
          right: 0,
        }
        style.ticket = {
          fontSize: 250,
          height: '455px',
          marginTop: '450px',
        }
        break
      case 'P5.1':
        style.wrapper = {
          left: 360,
          width: '720px',
        }
        style.ticket = {
          fontSize: 150,
          height: '270px',
          marginTop: '90px',
        }
        break
      default:
        break
    }
    return style
  }
  renderListTemplate () {
    const { templateName, queues } = this.state
    const tickets = queues.map(q => q.ticketNumber)
    const style = this.getTemplateStyle()
    return (
      <>
        <TicketWrapper style={style.wrapper}>
          {tickets.map((t, index) => (
            <Ticket key={index} style={style.ticket}>
              {t}
            </Ticket>
          ))}
        </TicketWrapper>
        <img
          src={`${process.env.REACT_APP_API_URL}/displays/getTemplate?templateName=${templateName}`}
          alt="display"
        />
      </>
    )
  }

  renderConfigError () {
    const { ip, deviceId, displayId } = this.state
    return <ConfigErrorWrapper>
      {`IP: ${ip || '-'}`}<br />
      {`ID dispositivo: ${deviceId || '-'}`}<br />
      {`ID display: ${displayId || '-'}`}
    </ConfigErrorWrapper>
  }

  render () {
    const { hasConfigError, alert } = this.state
    return (
      <>
        {hasConfigError && this.renderConfigError()}
        {alert ? this.renderAlertTemplate() : this.renderListTemplate()}
        <audio id="till-sound" src={`/assets/mp3/till.mp3`} />
      </>
    )
  }
}
export default App
