import axios from 'axios'

const BE_API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
  },
})

const INTERNAL_IP_API = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_IP_API_URL,
})

const MAGICINFO_TRANSCODE_API = axios.create({
  baseURL: process.env.REACT_APP_MAGICINFO_TRANSCODE_API_URL,
  headers: {
    'X-Gateway-APIKey': process.env.REACT_APP_MAGICINFO_TRANSCODE_API_KEY,
  },
})

export default {
  internalIp: {
    get: () => INTERNAL_IP_API.get('/'),
  },
  display: {
    getConfig: displayId =>
      BE_API.get('/displays/getConfiguration', { params: { displayId } }),
    getId: ip =>
      MAGICINFO_TRANSCODE_API.get(process.env.REACT_APP_MAGICINFO_TRANSCODE_API_ENDPOINT, { params: { ip } }),
  },
}
